import React from 'react'
import './PricingComp.css'

export default function PricingComp({title, price}) {
  return (
    <div className='card border-primary border-2 overflow-hidden'>
        <div className="pricing-card-header">
            <div className="item">
                <h2>{title}</h2>
            </div>
        </div>
        <div className="pricing-card-body">
            <div className="item">
                <div className='mb-3'>{price}</div>

                <p>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Facilis esse culpa reiciendis quisquam illo ex temporibus, autem atque earum mollitia, quibusdam quod voluptas, tenetur vel fugiat corrupti eum necessitatibus. Ut?
                </p>

                <a href="#" className="btn btn-danger">Start Now!</a>
            </div>
        </div>
    </div>
  )
}
