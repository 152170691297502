import React from 'react'
import UsageImage from '../../assets/3.png'

export default function UsagePage() {
  return (
    <div className='container mt-5 pt-5'>
        <div className="row">
            <div className="col">
                <h2 className='my-5 fw-bold'>Responsive Design for All Devices with Quality</h2>
                <p className='mb-5'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque neque ea, repellat, repudiandae deserunt voluptates eum voluptate, dolore facere aliquam necessitatibus aut! Optio alias blanditiis non temporibus ipsam velit voluptas.</p>
                <a href="#" className="btn btn-warning">Download Now!</a>
            </div>
            <div className="col">
                <img src={UsageImage} alt="usage" className='float-end' />
            </div>
        </div>
    </div>
  )
}
