import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import RcImage from '../assets/RC.png'
import './NavbarComp.css'

export default class NavbarComp extends Component {
    render() {
        return (
            <nav className="navbar navbar-expand-lg bg-light fixed-top navbar-dark app-navbar">
                <div className="container">
                    <NavLink className="navbar-brand d-flex" href="/">
                        <img
                            src={RcImage}
                            alt="Logo"
                            width="30"
                            height="24"
                            className="d-inline-block align-text-top"/>
                        &nbsp;Rumah Coding
                    </NavLink>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <div className="navbar-nav ms-auto">
                            <NavLink className={ (navData) => navData.isActive ? 'nav-link text-warning' : 'nav-link' } to="/">Home</NavLink>
                            <NavLink className={ (navData) => navData.isActive ? 'nav-link text-warning' : 'nav-link' } to="/features">Features</NavLink>
                            <NavLink className={ (navData) => navData.isActive ? 'nav-link text-warning' : 'nav-link' } to="/working">Working</NavLink>
                            <NavLink className={ (navData) => navData.isActive ? 'nav-link text-warning' : 'nav-link' } to="/usage">Usage</NavLink>
                            <NavLink className={ (navData) => navData.isActive ? 'nav-link text-warning' : 'nav-link' } to="/quality">Quality</NavLink>
                            <NavLink className={ (navData) => navData.isActive ? 'nav-link text-warning' : 'nav-link' } to="/pricing">Pricing</NavLink>
                            <NavLink className={ (navData) => navData.isActive ? 'nav-link text-warning' : 'nav-link' } to="/review">Review</NavLink>
                        </div>
                    </div>
                </div>
            </nav>
        )
    }
}
