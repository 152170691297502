import React from 'react'
import './FeatureCardComp.css'

export default function FeatureCardComp(props) {
  return (
    <div className='card card-body feature-card'>
        <i className={ props.icon + " display-4 my-3 text-primary" }></i>
        <h4>{props.title}</h4>
        <p>{props.body}</p>
    </div>
  )
}
