import React from 'react'
import PricingComp from '../../components/PricingComp'

export default function PricingPage() {
  return (
    <div className='container my-5'>
        <h1 className="pt-5 fw-bold text-center mb-5">Choose your plan</h1>

        <div className="row">
            <div className="col">
                <PricingComp title="STARTER" price="FREE"/>
            </div>
            <div className="col">
                <PricingComp title="PRO" price="$2.5"/>
            </div>
            <div className="col">
                <PricingComp title="PREMIUM" price="$5"/>
            </div>
            <div className="col">
                <PricingComp title="PLATINUM" price="$10"/>
            </div>
        </div>
    </div>
  )
}
