import React from 'react'

export default function ReviewComp({ image, body, name }) {
    const imgStyle = {
        position: 'absolute',
        top: 0,
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white'
    }
  return (
    <div className='border border-4 border-warning text-center p-4 position-relative mt-5'>
        <img src={image} alt="user" height="100" width="100" className='border border-4 border-warning rounded-circle' style={imgStyle}/>
        <p className='pt-5'>"{body}"</p>

        <b>- {name} -</b>
    </div>
  )
}
