import React from 'react'
import QualityImage from '../../assets/4.png'
import LogoImage from '../../assets/RC.png'
import './QualityPage.css'

export default function QualityPage() {
  return (
    <div className='container mt-5 pt-5'>
        <div className="row">
            <div className="col">
                <img src={QualityImage} alt="quality" className='quality-image' />
            </div>
            <div className="col">
                <img src={LogoImage} alt="logo" height="150" className='mb-2' />
                <h2 className="fw-bold mb-5">Easy to Manage Your Data</h2>
                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Provident voluptatum distinctio debitis obcaecati ipsum accusamus voluptate, quibusdam esse? Provident libero atque ratione ipsam aspernatur aperiam, velit omnis dolor perspiciatis alias.</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Et reprehenderit qui sint dolores beatae nisi ex magnam sed quasi facilis distinctio vel.</p>
                <a href="#" className="btn btn-info text-white btn-lg">Download Now!</a>
            </div>
        </div>
    </div>
  )
}
