import React from 'react'
import FeatureCardComp from '../../components/FeatureCardComp'

export default function FeaturesPage() {
  return (
    <div className='container mt-5 pt-5 text-center'>
      <h1 className='text-primary'>Awesome SPA Features</h1>
      <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Soluta molestiae natus, reprehenderit quidem eos maxime libero aperiam repellendus dolore? In, architecto! Sed eius fugit odio, dolorum voluptas rem veniam sunt!</p>
      <div className='row mt-5'>
        <div className="col">
          <FeatureCardComp
            icon="fa-brands fa-slideshare"
            title="Faster"
            body="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Soluta molestiae natus, reprehenderit quidem eos maxime libero aperiam repellendus dolore? In, architecto! Sed eius fugit odio, dolorum voluptas rem veniam sunt!"/>
        </div>
        <div className="col">
          <FeatureCardComp
            icon="fa-solid fa-flag"
            title="Design"
            body="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Soluta molestiae natus, reprehenderit quidem eos maxime libero aperiam repellendus dolore? In, architecto! Sed eius fugit odio, dolorum voluptas rem veniam sunt!"/>
        </div>
        <div className="col">
          <FeatureCardComp
            icon="fa-solid fa-user"
            title="Branding"
            body="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Soluta molestiae natus, reprehenderit quidem eos maxime libero aperiam repellendus dolore? In, architecto! Sed eius fugit odio, dolorum voluptas rem veniam sunt!"/>
        </div>
      </div>
    </div>
  )
}
