import React from 'react'
import WorkingComp from '../../components/WorkingComp'
import './WorkingPage.css'
import WorkingImage from '../../assets/2.png'

export default function WorkingPage() {
  return (
    <div className='bg-dark text-white working-container'>
        <div className="container pt-5">
            <div className="row pt-5">
                <div className="col">
                    <h2 className='mb-4'>How does this App Work ?</h2>
                    <WorkingComp
                        icon="fa-solid fa-user"
                        title="Make a Profile"
                        body="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consequatur ratione rem tempora suscipit ipsam nisi."/>
                    <WorkingComp
                        icon="fa-solid fa-user"
                        title="Download it for free"
                        body="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consequatur ratione rem tempora suscipit ipsam nisi."/>
                    <WorkingComp
                        icon="fa-solid fa-user"
                        title="Enjoy the App"
                        body="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consequatur ratione rem tempora suscipit ipsam nisi."/>
                    
                    <div className="row mt-5">
                        <div className="col">
                            <a href="#" className="btn btn-apple">
                                <i className="fa-brands fa-apple fs-3"></i>
                                Available on <br />
                                <b>APP STORE</b>
                            </a>
                        </div>
                        <div className="col">
                            <a href="#" className="btn btn-play-store float-end">
                                <i className="fa-brands fa-google-play fs-3"></i>
                                Available on <br />
                                <b>GOOGLE PLAY</b>
                            </a>
                        </div>
                    </div>

                </div>
                <div className="col">
                    <img src={WorkingImage} alt="working" className='float-end'/>
                </div>
            </div>
        </div>
    </div>
  )
}
