import React from 'react'
import ReviewComp from '../../components/ReviewComp'
import './ReviewPage.css'
import UserImage from '../../assets/avtar3.png'

export default function ReviewPage() {
  return (
    <div className="home-background">
        <div className="bg-white text-dark pb-5">
            <div className='container pt-5'>
                <h2 className='fw-bold mb-4 text-center pt-4'>User Review</h2>

                <ReviewComp
                    image={UserImage}
                    body="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quod consectetur eveniet totam architecto ab pariatur ullam animi nesciunt fugiat dignissimos amet dolore, sapiente at quia nihil maiores eaque deleniti? Laudantium."
                    name="Azfar" />
            </div>
        </div>

        <div className="container">
            <h2 className="text-center fw-bold mt-5">Let's start using today</h2>
            <div className="row">
                <div className="col">
                    <a href="#" className="btn btn-apple float-end">
                        <i className="fa-brands fa-apple fs-3"></i>
                        Available on <br />
                        <b>APP STORE</b>
                    </a>
                </div>
                <div className="col">
                    <a href="#" className="btn btn-play-store">
                        <i className="fa-brands fa-google-play fs-3"></i>
                        Available on <br />
                        <b>GOOGLE PLAY</b>
                    </a>
                </div>
            </div>
            <div className="text-center mt-3">Copyright &copy; 2022 Rumah Coding</div>
        </div>
    </div>
  )
}
