import React from 'react'
import './HomePage.css'
import PhoneImage from '../../assets/1.png'

export default function HomePage() {
    return (
        <div className='home-background'>
            <div className="container pt-5 overflow-hidden">
                <div className="row pt-5">
                    <div className="col">
                        <h1 className='display-4 fw-normal mb-5'>Awesome <span className='text-warning'>Final Project</span> Single Page Application</h1>
                        <p className='fs-5 pt-3 mb-5'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga pariatur tempora explicabo. Eos dolores vero placeat, repellat culpa vitae necessitatibus fugit eius repudiandae doloremque, odit officiis at! Hic, soluta tenetur.</p>
                        <div className="pt-3">
                            <a href="#" className="btn btn-light btn-home px-5 me-3">Download App</a>
                            <a href="#" className="btn btn-light btn-home px-5">Discover More</a>
                        </div>
                    </div>
                    <div className="col text-end">
                        <img src={PhoneImage} alt="phone" className='home-image'/>
                    </div>
                </div>
            </div>
        </div>
    )
}
