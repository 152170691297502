import React from 'react'

export default function WorkingComp(props) {
  return (
    <div className='row align-items-center mb-3'>
        <div className="col-auto pe-4">
            <div className="rounded-circle d-flex justify-content-center align-items-center" style={{ width: '75px', height: '75px', border: '5px solid purple' }}>
                <i className={ props.icon + " display-6" }></i>
            </div>
        </div>
        <div className="col">
            <h5 className="fw-bold">{ props.title }</h5>
            <p>{ props.body }</p>
        </div>
    </div>
  )
}
