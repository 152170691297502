import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NavbarComp from './components/NavbarComp';
import FeaturesPage from './pages/features/FeaturesPage';
import HomePage from './pages/home/HomePage';
import PricingPage from './pages/pricing/PricingPage';
import QualityPage from './pages/quality/QualityPage';
import ReviewPage from './pages/review/ReviewPage';
import UsagePage from './pages/usage/UsagePage';
import WorkingPage from './pages/working/WorkingPage';

function App() {
  return (
    <BrowserRouter>
      <NavbarComp/>
      <Routes>
        <Route path="/" element={<HomePage/>}/>
        <Route path="/features" element={<FeaturesPage/>}/>
        <Route path="/working" element={<WorkingPage/>}/>
        <Route path="/usage" element={<UsagePage/>}/>
        <Route path="/quality" element={<QualityPage/>}/>
        <Route path="/pricing" element={<PricingPage/>}/>
        <Route path="/review" element={<ReviewPage/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
